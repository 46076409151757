import React from "react";
import { graphql } from "gatsby";
import Layout from "../layout";
import SEO from "../components/seo";
import styled from "styled-components";
import {
  darkBlue,
  Subtitle,
  InnerWrapper,
  screen,
  mainWhite,
} from "../components/common/variables";

const Wrapper = styled.div`
  background: ${mainWhite};
  @media ${screen.large} {
    padding-top: 70px;
  }

  .inner-wrapper {
    padding: 40px 35px;
    @media ${screen.medium} {
      padding: 110px 35px;
    }
    @media ${screen.large} {
      padding: 90px 35px;
    }
  }

  .title {
    margin-bottom: 20px;
  }

  .content {
    p {
      color: ${darkBlue};
      font-size: 1rem;
      margin-bottom: 10px;
      @media ${screen.large} {
        font-size: 1.1rem;
        margin-bottom: 20px;
      }
    }

    li {
      color: ${darkBlue};
      font-size: 1.1rem;
      line-height: 1.5;
      list-style-type: disc;
      margin-left: 18px;
      margin-bottom: 10px;

      &:last-child {
        margin-bottom: 20px;
      }
    }

    h2 {
      color: ${darkBlue};
      font-size: 1.1rem;
      margin: 30px 0 10px 0;
      @media ${screen.large} {
        font-size: 2rem;
        margin: 50px 0 20px 0;
      }
    }

    h6 {
      color: ${darkBlue};
      font-size: 1rem;
      font-weight: 400;
      @media ${screen.large} {
        font-size: 1.1rem;
        font-weight: 700;
      }
    }
  }
`;
const Privacy = ({ data }) => {
  const content = data.content.data.privacy.html;
  return (
    <Layout>
      <SEO title="Privacy" />
      <Wrapper>
        <InnerWrapper className="inner-wrapper">
          <Subtitle className="title" dark>
            Global Adviser Alpha Pty Ltd and Data Privacy
          </Subtitle>
          <div
            className="content"
            dangerouslySetInnerHTML={{ __html: content }}
          />
        </InnerWrapper>
      </Wrapper>
    </Layout>
  );
};

export default Privacy;

export const data = graphql`
  {
    content: prismicPrivacyAndDisclaimer {
      data {
        privacy {
          html
        }
      }
    }
  }
`;
